<template>
  <div class="course"  :style="{ backgroundImage: 'url(' + require('@/assets/images/CourseCard2/'+ elem.image +'.png') + ')' }">
    <!-- <div class="course"> -->
    <div class="card" @mouseover="rotateCard" @mouseleave="rotateCardBack">
      <div class="course__content card-front">
        <img :src="course.icon" />
        <p class="titleStyle" v-html="course.name"></p>
      </div>
      <div class="course__content desc card-back">
        <div class="desc__text">
          <p v-html="course.flip_box_content"></p>
          <router-link :to="`/courses/${course.slug}`" class="desc__text-more">{{ $t("more") }}</router-link>
          <!-- <div class="moreclass" v-if="course.status">
            <div>
              <img :src="require('@/assets/icons/settings.svg')" alt="settings" /> <span>Ожидайте вскоре</span>
            </div>            
            <label>(пройти для информации)</label>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["course", "elem"],
  data() {
    return {
      isCardFlipped: false,
    };
  },
  methods: {
    rotateCard() {
      this.isCardFlipped = true;
    },
    rotateCardBack() {
      this.isCardFlipped = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.moreclass {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span, label {
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.3571))), 18px);
    color: white;
  }
}
.desc {
  width: 101% !important;
    background: rgba(0, 50, 135, 1) !important;
  height: 100% !important;
    cursor: default !important;
  padding: 15px;
  //   box-shadow: 0 4px 24px rgba(0,0,0,.6) !important;
  // -webkit-backdrop-filter: blur(2px) !important;
  // backdrop-filter: blur(2px) !important;
  // background: black !important;
  border: none !important;
  &__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 9px;
    text-align: center;
    justify-content: center;
    color: $whiteText !important;
    line-height: normal;
    a {
      font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.8824))), 20px);
    color: $whiteText !important;
    }
    p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    }
    @media (max-width: $tablet) {
      width: 80%;
    }
    &-more {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.course {
  margin-top: 45px;
  width: 100%;
  height: 100%;
  background-size: cover;
  display: flex;
  @media (max-width: $tablet) {
    background-size: cover;
  }
  &__content {
    cursor: pointer;
    height: 75.11%;
    width: 87.07%;
    margin: auto;
    //   background: hsla(0, 0%, 100%, 0.3);
    //   -webkit-backdrop-filter: blur(10px);
    //   backdrop-filter: blur(0.5px);
    box-sizing: border-box;
    //   border-left: 6px solid #000000;
    background: rgba(0, 50, 135, 0.8);
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    .titleStyle {
      color: $whiteText;
      text-align: center;
      font-size: min(max(16px, calc(1rem + ((1vw - 3.5px) * 0.7643))), 28px);
      @media (max-width: $tablet) {
        line-height: 26px;
      }
    }
    img {
      max-height: 70px;
    }
  }
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-front,
.card-back {
  position: absolute;
  backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

.card:hover {
  transform: rotateY(180deg);
}
</style>