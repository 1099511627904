<template>
  <div class="select">
    <div
        class="select__selected"
        ref="selectBtn"
        @click="open = !open"
        :class="{ open: open, selectStyle: type === 'select' }"
    >
      <template v-for="item in options" :key="item">
        <span v-if="item.value == getLang" class="maappp">
          <img
              style="width: 32px; height: 24px; object-fit: cover"

              :src="item.img"
              alt=""
          />
          <div class="maappp-line"></div>
          <span>{{ item.init }}</span>
        </span>
      </template>
      <span v-if="type === 'select' && style === 'gray'" class="select__arrow">
        <img src="@/assets/icons/profile.svg" alt="" />
      </span>
      <span v-if="type === 'select' && style === 'black'" class="select__arrow">
        <img src="@/assets/icons/profile.svg" alt="" />
      </span>
      <span v-if="type === 'lang'" class="select__arrow select__arrow_lang">
        <img src="@/assets/icons/profile.svg" alt="" />
      </span>
    </div>
    <transition name="fade">
      <div class="select__content" v-if="open" v-click-out-side="hideSelect">
        <ul
            class="select__list"
            :class="{ selectStyle: type === 'select', lang: type === 'lang' }"
        >
          <template v-for="(option, idx) in options" :key="idx">
            <li
                v-if="option.img && option.value !== lang"
                @click="selectOption(option)"
            >
              <img :src="option.img" alt="" />
              <span>{{ $t(`langs.${option.value}`) }}</span>
            </li>
          </template>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import { mapGetters } from "vuex";

export default {
  name: "custom-select",
  directives: {
    clickOutSide,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    openLang: {
      type: Boolean,
    },
    selectedOption: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "select",
    },
    clear: {
      type: Boolean,
      required: false,
    },
    style: {
      type: String,
      required: false,
      default: "gray",
    },
  },
  data() {
    return {
      lang: "",
      selected: this.selectedOption
          ? this.selectedOption
          : this.placeholder
              ? this.placeholder
              : this.options.length > 0
                  ? this.options[0]
                  : null,
      open: false,
    };
  },
  methods: {
    hideSelect(e) {
      const selectBtn = this.$refs.selectBtn;
      if (!(e.target == selectBtn || selectBtn.contains(event.target))) {
        this.open = false;
      }
    },
    selectOption(option) {
      this.selected = option;
      this.open = false;
      this.$emit("select", option);
    },
  },
  mounted() {
    this.lang = this.getLang;
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  watch: {
    openLang() {
      this.open = this.openLang
    },
    clear: function () {
      if (this.clear) this.selected = this.placeholder;
    },
    selectedOption() {
      if (this.selectedOption) {
        this.selected = this.selectedOption;
      } else this.selected = this.placeholder;
    },
  },
};
</script>

<style lang="scss" scoped>
.select__list {
  z-index: 100;
  margin: 0;
}
.maappp {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #F2F2F2;
  font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.2679))), 17px);
  &-line {
    width: 1px;
    height: 23px;
    background: white;
  }
  img {
    box-shadow: 0px 0px 8px -3px rgba(255, 0, 0, 0.34);
    width: 18px;
    height: 18px;
  }
  @media screen and (max-width: $mobile) {
    color: #001D5B;
  }
}
.select {
  display: inline-block;
  position: relative;
  //z-index: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #77757f;
  &__list {
    position: absolute;
    top: 38px;
    left: 0;
    padding: 0;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 15px 20px;
      border-bottom: 0.75px solid #E3E5E6;
      letter-spacing: 0.02em;
      background: #ffffff;
      text-transform: uppercase;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1786))), 14px);
      font-weight: 600;
      line-height: 16px;
      img {
        width: 24px;
        height: 15px;
      }
      &:not(:last-child) {
        // margin: 0 0 5px 0;
      }
      &:hover {
        background: #D9D9D9;
      }
    }

    &.lang {
      top: calc(100% + 5px);
    }
    img {
      width: 32px;
      // height: 21px ;
      height: 24px;
      object-fit: cover;
    }

    &.selectStyle {
      top: calc(100% + 10px);
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      left: -1px;
      width: calc(100% + 2px);
      padding: 10px 20px;
    }
  }

  &__selected {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.selectStyle {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 18px 20px;
    }

    &.open {
      .select__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__arrow {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease 0s;

    &_lang {
      // margin: 0 0 0 2px;
      // width: 20px;
      // height: 20px;
    }
  }
}
ul li {
  list-style: none;
}
</style>