<template>
  <div class="desctop">
    <div class="backgroundHeader">
      <div class="header">
        <router-link to="/" class="header__top">
          <img src="@/assets/logoWhite.svg" alt="logoWhite" />
        </router-link>
        <div class="header__bot">
          <div class="container">
            <div class="header__bot__left">
              <router-link to="/">{{ $t('main') }}</router-link>
              <router-link to="/courses">{{ $t('courses') }}</router-link>
              <router-link to="/news">{{ $t('news') }}</router-link>
              <router-link to="/faq">FAQ</router-link>
            </div>
            <div class="header__bot__right">
              <div class="header__bot__right__lang">
                <select-component
                    class="header__bot__right__lang-component"
                    type="'lang'"
                    :options="languages"
                    @select="getLang"
                />
              </div>
              <div style="position: relative;">
                <input class="search" v-model="search" @input="searchText" @blur="closeSearch" />
                <div class="res-search" v-if="isSearch">
                  <div class="courses">
                    <div
                        @click="goToItem(item)"
                        data-aos="fade-right"
                        data-aos-offset="0"
                        data-aos-easing="ease-in-sine"
                        data-aos-duration="500" class="courses__items"
                        v-for="(item, idx) in searchResults" :key="idx"
                    >
                      <img :src="item.icon" alt="" />
                      <div class="courses__items-text">
                        <span class="scale-text">{{ item.name }}</span>
                        <p v-html="item.flip_box_content" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img style="cursor: pointer;" @click="$router.push('/basket')" src="@/assets/icons/shopping-cart.svg" alt="basket"/>
              <img style="cursor: pointer;" @click="isOpen = true" src="@/assets/icons/profile.svg" alt="profile" v-if="!getAuth"/>
              <img style="cursor: pointer;" @click="$router.push('/profile/info')" src="@/assets/icons/profile.svg" alt="profile" v-else/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mobile mob-header">
    <router-link to="/" class="header__top">
      <img src="@/assets/logoWhite.svg" alt="logoWhite" />
    </router-link>
    <div class="backHeaderM">
      <div class="headerM container">
        <div class="headerM__top">
          <div class="headerM-left">
            <div @click.prevent="showBurgerMenu" :class="{ 'burger-active': isBurgerActive }" class="header__burger">
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
          <input class="search" v-model="search" @input="searchText" @blur="closeSearch" />
          <div class="res-search" v-if="isSearch">
            <div class="courses">
              <div
                  @click="goToItem(item)"
                  data-aos="fade-right"
                  data-aos-offset="0"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="500" class="courses__items"
                  v-for="(item, idx) in searchResults" :key="idx"
              >
                <img :src="item.icon" alt="" />
                <div class="courses__items-text">
                  <span class="scale-text">{{ item.name }}</span>
                  <p v-html="item.flip_box_content" />
                </div>
              </div>
            </div>
          </div>
          <img style="cursor: pointer;" @click="$router.push('/basket')" src="@/assets/icons/shopping-cart.svg" alt="basket"/>
          <img style="cursor: pointer;" @click="isOpen = true" src="@/assets/icons/profile.svg" alt="profile" v-if="!getAuth"/>
          <img style="cursor: pointer;" @click="$router.push('/profile/info')" src="@/assets/icons/profile.svg" alt="profile" v-else/>
        </div>
        <div class="headerM__bot" :class="{'headerM__bot1': isBurgerActive}">
          <router-link to="/">{{ $t('main') }}</router-link>
          <router-link to="/courses">{{ $t('courses') }}</router-link>
          <router-link to="/news">{{ $t('news') }}</router-link>
          <router-link to="/contacts">{{ $t('contacts') }}</router-link>
          <router-link to="/faq">FAQ</router-link>
          <select-component
              class="headerM__bot__lang"
              type="'lang'"
              :options="languages"
              @select="getLang"
          />
        </div>
      </div>
    </div>
  </div>

  <ModalComponent :is-open="isOpen" @close="isOpen = false">
    <AuthBlock v-on:close="isOpen = false" />
  </ModalComponent>

</template>
<script>
import SelectComponent from '../SelectComponent.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalComponent from '../myComponents/ModalComponent.vue';
import AuthBlock from '../AuthBlock.vue';
import axios from "axios";
export default {
  components: { SelectComponent, ModalComponent, AuthBlock },
  data() {
    return {
      isBurgerActive: false,
      isOpen: false,
      isSearch: false,
      searchResults: [],
      search: "",
      languages: [],
    }
  },
  computed: {
    ...mapGetters(['getAuth']),
  },
  methods: {
    ...mapMutations(["UPDATE_LANG"]),
    ...mapActions(["requestUser"]),
    getLang(option) {
      localStorage.setItem("lang", option.value);
      location.reload();
    },

    showBurgerMenu() {
      return this.isBurgerActive = !this.isBurgerActive,
          this.openMarsh = false
    },

    async searchText() {
      this.isSearch = true;
      this.searchResults = [];

      try {
        const response = await axios.get(`search?search=${this.search}`);
        this.searchResults = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async created() {
      if (localStorage.getItem("access_token")) {
        await this.requestUser()
      }
    },
    async loadLanguages() {
      try {
        const response = await axios.get('languages');
        this.languages = response.data.data.map(language => ({
          value: language.slug,
          init: language.name,
          img: language.image_url,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    goToItem(item) {
      switch (item.type) {
        case "courses":
          this.$router.push(`/courses/${item.slug}`);
          break;
        case "news":
          this.$router.push(`/news/${item.slug}`);
          break;
        case "faqs":
          this.$router.push(`/faq`);
          break;
        default:
          break;
      }
    },
    closeSearch() {
      setTimeout(() => {
        this.isSearch = false
      }, 200);
    }
  },
  async created() {
    if (localStorage.getItem("access_token")) {
      await this.requestUser()
    }
    await this.loadLanguages();
  },
}
</script>
<style lang="scss" scoped>
.desctop {
  position: relative;
  z-index: 3;
}
@media screen and (max-width: $tablet) {
  .container {
    width: 100%;
  }
}
.header__burger {
  display: block;
  width: 30px;
  height: 18px;
  position: relative;
  cursor: pointer;
  z-index: 9;

  & span {
    transition: all .3s ease 0s;
    top: calc(50% - 1px);
    left: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #FFFFFF;

    &:first-child {
      top: 0;
    }

    &:last-child {
      top: auto;
      bottom: 0;
    }
  }

  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.backHeaderM {
  left: 0;
  background: #001D5B;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.59);
  z-index: 997;
  right: 0;
}
.mob-header {
  min-height: 56px;
  margin-bottom: 40px;
  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    .headerM-logo {
      width: auto;
      margin: 20px 90px 20px 90px;
    }
  }
}
.headerM {
  &__top {
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }
  &__bot {
    transition: all 0.5s ease;
    display: none;
    opacity: 0;;
    margin-top: 16px;
    flex-direction: column;
    gap: 9px;
    p {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 16px;
    }
    &__lang {
      color: #001D5B;
    }
    a {
      text-decoration: none;
      color: #001D5B;
    }
  }
  &__bot1 {
    padding: 25px 35px;
    background: white;
    display: flex;
    opacity: 1;
    -webkit-animation: fadeIn-4225ec55 0.5s ease-in-out;
    animation: fadeIn-4225ec55 .5s ease-in-out;
  }
  &-logo {
    width: 50%;
  }
  &-left {
    display: flex;
    gap: 9px;
    align-items: center;
  }
}

.backgroundHeader {
  padding: 20px 0 0px 0;
  margin-bottom: 40px;
  background: rgba(0, 24, 149, 0.5);
}
.header {
  &__top {
    margin: 14px 0 22px 0;
    display: flex;
    justify-content: center;
  }
  &__bot {
    padding: 5px 0;
    background: rgba(0, 0, 0, 0.3);
    .container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
    &__left {
      display: flex;
      gap: 25px;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.7843))), 17px);
      a {
        height: fit-content;
        cursor: pointer;
        font-weight: 400;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 4.24px) * 0.6684))), 27px);
        color: white;
        text-decoration: none;
        transition: all 0.5s ease;
        &:hover {
          text-decoration: underline;
          color: $whiteText;
        }
      }
      .pUpgrade {
        color: $whiteText;
        text-decoration: underline !important;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 25px;
      &__lang {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.7843))), 20px);
        color: white;
        &-line {
          width: 1px;
          height: 23px;
          background: white;
        }
        &-component {
          margin-bottom: -3px;
        }
      }
    }
  }
}


.search {
  position: relative;
  width: 25px;
  border-radius: 50%;
  margin-left: auto;
  padding: 10px;
  padding: 10px 0;
  color: transparent;
  transition-duration: 500ms;
  cursor: pointer;
  font-size: 15px;
  background: transparent;
  border: 1px solid transparent;
  overflow: hidden;
  background-image: url('@/assets/icons/searchBig.svg');
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: 45% 50%;
}
.search:focus {
  width: 200px;
  cursor: text;
  padding-left: 40px;
  color: white !important;
  // outline: 1px soild #993300;
  // filter: brightness(0) saturate(100%) invert(61%) sepia(35%) saturate(5196%) hue-rotate(149deg) brightness(91%) contrast(101%);
  border: 1px solid black !important;
  background: url('@/assets/icons/searchBig.svg');
  background-repeat: no-repeat;
  background-size: 22px;
  border-radius: 25px;
  background-position: 10px 50%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}


.burger-active {
  span {
    transform: scale(0);

    &:first-child {
      transform: rotate(-45deg);
      top: calc(50% - 1px);
    }

    &:last-child {
      transform: rotate(45deg);
      bottom: calc(50% - 1px);
    }
  }
}
.res-search {
  position: absolute;
  background: rgba(0, 50, 135, 1);
  top: 120%;
  left: 0;
  width: 150%;
  padding: 15px;
}
.courses {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &__items {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 15px;
    background: rgba(1, 25, 122, 0.8);
    border: 1px solid white;
    img {
      width: 20px;
      max-height: 20px;
    }
    &-text {
      display: flex;
      flex-direction: column;
      gap: 5px;
      p {
        color: white;
        font-size: 12px;
        overflow-y: auto;
        text-decoration: none !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      span {
        color: white;
        font-size: 15px;
        text-decoration: none !important;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: $mobile) {
    margin-top: 18px;
    &__items {
      padding: 20px 10px;
      height: auto;
      img {
        width: 50px;
        max-height: 70px;
      }
    }
  }
}
.router-link-active {
  text-decoration: underline !important;
  @media screen and (max-width: $mobile) {
    color: #001D5B !important;
  }
}
</style>