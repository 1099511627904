<template>
  <div class="auth" v-if="!codeTab && !forgorPass">
    <h1 class="auth-title">
      {{
        type === 0
            ? $t('auth.authorization')
            : $t('auth.register')
      }}
    </h1>
    <div class="auth-inputs">
      <input
          type="text"
          v-if="type === 1"
          :placeholder="$t('placeholders.fio')"
          v-model="v$.name.$model"
          @keyup="onlyText()"
          @keyup.enter="onEnterKey"
      />
      <template v-for="(error, index) of v$.name.$errors" :key="index">
        <p class="errorValid">{{ error.$message }}</p>
      </template>

      <input
          type="email"
          v-model="v$.email.$model"
          placeholder="Email"
          @keyup.enter="onEnterKey"
      />
      <template v-for="(error, index) of v$.email.$errors" :key="index">
        <p class="errorValid">{{ error.$message }}</p>
      </template>

      <input
          type="text"
          v-if="type === 1"
          :placeholder="$t('placeholders.phone')"
          v-model="v$.phone.$model"
          v-imask="phoneMask"
          @input="isNumber"
          @accept="onAccept"
          @complete="onComplete"
          @keyup.enter="onEnterKey"
          @blur="phoneExistCheck"
      />
      <template v-for="(error, index) of v$.phone.$errors" :key="index">
        <p class="errorValid">{{ error.$message }}</p>
      </template>

      <select
          v-if="type === 1"
          class="auth-inputs-lang"
          v-model="v$.selectedLanguage.$model"
          @change="checkLang"
      >
        <option value="" disabled> {{ $t('regLang') }} </option>
        <option
            v-for="(lang, index) in languagesArray"
            :key="index"
            :value="lang.slug"
        >
          {{ lang.name }}
        </option>
      </select>
      <template v-for="(error, index) of v$.selectedLanguage.$errors" :key="index">
        <p class="errorValid">{{ error.$message }}</p>
      </template>

      <input
          type="password"
          v-model="v$.password.$model"
          :placeholder="$t('placeholders.password')"
          @keyup="onlyLatin()"
          @keyup.enter="onEnterKey"
      />
      <!-- {{ v$.password.containsUpperCase }} -->
      <!-- <template v-for="(error, index) of v$.password.$errors" :key="index">
          <p class="errorValid">{{ error.$message }}</p>
      </template> -->
      <template v-if="v$.password.$error && type === 1">
        <p class="errorValid" v-if="v$.password.required.$invalid">{{$t('errors.required')}}</p>
        <p class="errorValid" v-if="v$.password.minLength.$invalid">{{$t('errors.minLength', {value: 8})}}</p>
        <p class="errorValid" v-if="v$.password.containsUpperCase.$invalid">{{$t('errors.containsUpperCase')}}</p>
        <p class="errorValid" v-if="v$.password.containsNumber.$invalid">{{$t('errors.containsNumber')}}</p>
        <p class="errorValid" v-if="v$.password.containsSymbol.$invalid">{{$t('errors.containsSymbol')}}</p>
        <p class="errorValid" v-if="v$.password.noSpaces.$invalid">{{$t('errors.noSpaces')}}</p>
      </template>
<!--      <template v-if="v$.password.$errors && type === 0">-->
<!--        <p class="errorValid" v-if="v$.password.required.$invalid">{{$t('errors.required')}}</p>-->
<!--      </template>-->

      <input
          type="password"
          v-if="type === 1 || type === 2"
          v-model="v$.passwordConfirm.$model"
          :placeholder="$t('placeholders.repeatpassword')"
          @keyup.enter="onEnterKey"
      />
      <template v-for="(error, index) of v$.passwordConfirm.$errors" :key="index">
        <p class="errorValid">{{ error.$message }}</p>
      </template>
    </div>
    <button class="auth-button" v-if="type === 0" @click="loginAccount" @keyup.enter="onEnterKey">{{$t('auth.login')}}</button>
    <button class="auth-button" v-else-if="type === 2" @click="codeTab = true">
      {{ $t('auth.next') }}
    </button>
    <button class="auth-button" v-else @click="registerAccount" @keyup.enter="onEnterKey">{{$t('auth.zaregister')}}</button>
    <div class="auth__actions" v-if="type === 0">
      <div class="auth__actions-btn">
        <p @click="forgorPass = true">{{$t('auth.forgot')}}</p>
        <p @click="type = 1">{{$t('auth.register')}}</p>
      </div>
      <p>{{ $t('auth.and') }}</p>
      <div class="auth__actions-social" >
<!--        <div class="auth__actions-social-item" @click="loginFB">-->
<!--          <img :src="require('@/assets/icons/facebook.svg')" alt="facebook" />-->
<!--          <span>Facebook</span>-->
<!--        </div>-->
      </div>
      <GoogleLogin :callback="callback"/>
    </div>
    <div class="auth__regis" v-else-if="type === 1">
      <div class="auth__regis-check">
        <input id="checkb" type="checkbox" v-model="privacy" />
        <label for="checkb"
        >{{ $t('auth.personal') }}</label
        >
      </div>
      <span style="margin-top: -15px; text-align: left;" v-if="!privacy && send" class="errorValid">{{$t('errors.required')}}</span>
      <p>{{ $t('auth.and') }}</p>
      <div class="auth__regis-social" >
<!--        <div class="auth__regis-social-item" @click="loginFB">-->
<!--          <img :src="require('@/assets/icons/facebook.svg')" alt="facebook" />-->
<!--          <span>Facebook</span>-->
<!--        </div>-->
      </div>
      <GoogleLogin :callback="callback"/>
    </div>
  </div>

  <div class="auth code" v-if="forgorPass">
    <h1 class="auth-title">{{ $t('auth.recovery') }}</h1>
    <template v-if="steppp === 1">
      <div class="auth-inputs">
        <input
            type="text"
            v-model="for_email"
            placeholder="Email"
        />
      </div>
      <button @click="sendSms" class="auth-button">{{ $t('auth.next') }}</button>
    </template>
    <template v-if="steppp === 2">
      <label>{{ $t('auth.sms') }}</label>
      <div class="auth-inputs">
        <input
            type="text"
            v-model="for_code"
            :placeholder="$t('placeholders.code')"
        />
      </div>
      <label class="code-sec">{{ timeLeft > 0 ? $t('auth.getsms', { value: timeLeft }) : $t('auth.resendCodeMessage') }}</label>
      <button v-if="timeLeft === 0" class="auth-button" @click="resendCode">{{ $t('auth.resendCode') }}</button>
      <button class="auth-button" @click="checkCode">{{ $t('auth.apply') }}</button>
      <div class="auth__regis-check code-che">
        <input id="checkb" ref="termsCheckbox" type="checkbox" v-model="privacy" />
        <label for="checkb"
        >{{ $t('auth.personal') }}</label
        >
      </div>
      <span style="margin-top: -15px; text-align: left;" v-if="!privacy && send" class="errorValid">{{$t('errors.required')}}</span>
    </template>
    <template v-if="steppp === 3">
      <div class="auth-inputs">
        <input type="password" v-model="v$.password.$model" :placeholder="$t('placeholders.password')" @keyup="onlyLatin()" />
        <!-- {{ v$.password.containsUpperCase }} -->
        <!-- <template v-for="(error, index) of v$.password.$errors" :key="index">
            <p class="errorValid">{{ error.$message }}</p>
        </template> -->
        <template v-if="v$.password.$error">
          <p class="errorValid" v-if="v$.password.required.$invalid" :key="index">
            {{$t('errors.required')}}
          </p>
          <p class="errorValid" v-if="v$.password.minLength.$invalid" :key="index">
            {{$t('errors.minLength', {value: 8})}}
          </p>
          <p class="errorValid" v-if="v$.password.containsUpperCase.$invalid" :key="index">
            {{$t('errors.containsUpperCase')}}
          </p>
          <p class="errorValid" v-if="v$.password.containsNumber.$invalid" :key="index">
            {{$t('errors.containsNumber')}}
          </p>
          <p class="errorValid" v-if="v$.password.containsSymbol.$invalid" :key="index">
            {{$t('errors.containsSymbol')}}
          </p>
          <p class="errorValid" v-if="v$.password.noSpaces.$invalid" :key="index">
            {{$t('errors.noSpaces')}}
          </p>
        </template>

        <input
            type="password"
            v-model="v$.passwordConfirm.$model"
            :placeholder="$t('placeholders.repeatpassword')"
        />
        <template v-for="(error, index) of v$.passwordConfirm.$errors" :key="index">
          <p class="errorValid">{{ error.$message }}</p>
        </template>
      </div>
      <button @click="changePass" class="auth-button">{{ $t('auth.apply') }}</button>
    </template>
  </div>

</template>
<script>
import { router } from '@/_helpers';
import { accountService } from '@/_services';
import { googleTokenLogin, decodeCredential } from "vue3-google-login"

import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email, sameAs } from "@vuelidate/validators";

import { IMaskDirective } from "vue-imask";
import { mapActions } from 'vuex';

export default {
  directives: {
    imask: IMaskDirective,
  },
  emits: ['close'],
  data: () => ({
    v$: useVuelidate(),
    email: "",
    password: "",
    passwordConfirm: "",
    steppp: 1,
    privacy: true,
    forgorPass: false,
    send: false,
    phone: "",
    name: "",
    type: 0,
    acstok: "",
    selectedLanguage: "",
    langSelected: false,
    languagesArray: [],
    codeTab: false,
    phoneMask: {
      mask: "+{7} ({000}) 000-00-00",
      lazy: true,
    },
    operators: [
      700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
      761, 762, 763, 764, 771, 775, 776, 777, 778,
    ],
    timeLeft: 59,
    timer: null,
  }),
  setup(props, context) {
    if (accountService.accountValue) {
      router.push('/');
    }
    const login = () => {
      googleTokenLogin().then((response) => {
        console.log("Handle the response", response)
      })
    }

    return {
      loginGoogle: login
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage(
            this.$t('errors.minLength', {value: 3}),
            minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        email: helpers.withMessage((this.$t('errors.email')), email),
      },
      phone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phoneFormat')), minLength(18)
        ),
      },
      selectedLanguage: {
        required: helpers.withMessage((this.$t('errors.required')), required),
      },
      password: {
        required: required,
        minLength: minLength(8),
        // alpha: alpha && helpers.regex('alpha', /^[A-Za-z]+$/),
        containsUpperCase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /\d/.test(value);
        },
        containsSymbol(value) {
          return /[!@#$%^&*]/.test(value);
        },
        noSpaces(value) {
          return !/\s/.test(value);
        },
      },
      passwordConfirm: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        sameAs: helpers.withMessage(
            (this.$t('errors.sameAs')),
            sameAs(this.password)
        ),
      }
    };
  },
  computed: {
    checkLang() {
      this.languageSelected = !!this.selectedLanguage;
    },
  },
  mounted() {
    this.$axios.get('languages')
      .then(response => {
        this.languagesArray = response.data.data;
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    ...mapActions(["requestUser", "logoutUser"]),
    // async loginFB() {
    //   await accountService.login();
    //   this.requestUser();
    //   setTimeout(() => {
    //     this.$emit('close');
    //     this.$router.push('/profile/info')
    //   }, 200);
    // },
    callback (response) {
      const userData = decodeCredential(response.credential)
      this.$axios.post("auth/google", {
        email: userData.email,
        name: userData.name,
        language: this.selectedLanguage
      })
      .then(res => {
        localStorage.setItem("access_token", res.data.data.access_token)
        this.$toaster.success(this.$t('toasters.successLogin'));
        this.reserForm()
        this.requestUser()
        setTimeout(() => {
          this.$router.push('/profile/info')
          this.$emit('close')
        }, 200);
      })
      .catch(err => {
        this.$toaster.error(err.response.data.error);

        // this.logoutUser()
      })
    },
    sendSms() {
      this.$axios.post('forgot-password', {
        email: this.for_email
      }).then(res => {
        this.$toaster.success(res.data.message)
        this.steppp = 2
        this.startTimer();
      }).catch(err => {
        this.$toaster.error(err.response.data.message)
      })
    },
    phoneExistCheck() {
      this.$axios.post('check-phone', {
        phone: this.phone,
      })
          .then(response => {
          })
          .catch(err => {
            this.$toaster.error(err.response.data.message);
          })
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    resendCode() {
      this.timeLeft = 59;
      this.sendSms();
    },
    checkCode() {
      this.$axios.post('check-email-code', {
        email: this.for_email,
        code: this.for_code
      }).then(res => {
        const checkbox = this.$refs.termsCheckbox;
        if (checkbox.checked) {
          this.acstok = res.data.data.token
          this.$toaster.success(res.data.message)
          this.steppp = 3
        } else {
          this.$toaster.error(this.$t('toasters.checkbox'));
        }
      }).catch(err => {
        this.$toaster.error(err.response.data.message)
      })
    },
    changePass() {
      this.v$.password.$validate();
      this.v$.passwordConfirm.$validate();

      if (!this.v$.password.$invalid && !this.v$.passwordConfirm.$invalid) {
        this.$axios.post('reset-password', {
              password: this.password,
              password_confirmation: this.passwordConfirm
            },
            {
              headers: {
                Authorization: `Bearer ${this.acstok}`,
              },
            }).then(res => {
          this.$toaster.success(res.data.message)
          this.$emit('close')
        }).catch(err => {
          this.$toaster.error(err.response.data.message)
        })
      } else {
        this.$toaster.error(this.$t('toasters.fullInput'))
      }
    },
    onEnterKey() {
      if (this.type === 0) {
        this.loginAccount();
      } else if (this.type === 1 || this.type === 2) {
        this.registerAccount();
      }
    },
    registerAccount() {
      this.send = true
      this.v$.$validate();
      if (!this.v$.$invalid && this.privacy) {
        this.$axios.post("registration", {
          name: this.name,
          email: this.email,
          phone: this.phone,
          password: this.password,
          password_confirmation: this.passwordConfirm,
          language: this.selectedLanguage
        })
            .then(res => {
              localStorage.setItem("access_token", res.data.access_token)
              this.$toaster.success(this.$t('toasters.successAuth'))
              this.reserForm()
              setTimeout(() => {
                this.requestUser()
                this.$emit('close')
                this.$router.push('/profile/info')
              }, 200);
            })
            .catch(err => {
              for (let field in err.response.data.error) {
                this.$toaster.error(err.response.data.error[field][0]);
              }
              this.logoutUser()
            })
      }
      else {
        this.$toaster.error(this.$t('toasters.fullInput'))
      }
    },

    loginAccount() {
      this.send = true
      this.v$.email.$validate() && this.v$.password.$validate;
      if (!this.v$.email.$invalid && this.password) {
        this.$axios.post("login", {
          email: this.email,
          password: this.password
        })
            .then(res => {
              localStorage.setItem("access_token", res.data.data.access_token)
              this.$toaster.success(this.$t('toasters.successLogin'));
              this.reserForm()
              setTimeout(() => {
                this.requestUser()
                this.$emit('close')
                this.$router.push('/profile/info')
              }, 200);
            })
            .catch(err => {
              if (err.response.data.status === 500) {
                this.$toaster.error(err.response.data.data.message);
              }
              if(err.response.data.error) {
                if (err.response.data.error.email) {
                  this.$toaster.error(err.response.data.error.email[0]);
                } else {
                  this.$toaster.error(err.response.data.error.message);
                }
              }
            })
      }
      else {
        this.$toaster.error(this.$t('toasters.fullInput'))
      }
    },

    reserForm() {
      this.name = "",
          this.email = "",
          this.phone = "",
          this.password = "",
          this.passwordConfirm = "",
          this.v$.$reset()
    },
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error(this.$t('toasters.phone'));
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },
    onlyLatin() {
      this.password = this.password.replace(/[^a-zA-Z0-9\s.!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '');
    },
    onlyText() {
      this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function (match) {
        return match.substr(0, 1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.auth {
  width: 470px;
  padding: 37px 70px;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &-title {
    font-weight: 700;
    font-size: min(max(18px, calc(1.125rem + ((1vw - 3.5px) * 0.6369))), 28px);
    line-height: 130%;
    text-align: center;
    color: $whiteText;
  }
  &-inputs {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 15px;
    gap: 15px;
    input {
      background: #ffffff;
      border: 1px solid #c1c1c1;
      padding: 15px 20px;
      outline: none;
    }
    select {
      padding: 15px 20px;
      border: 1px solid #c1c1c1;
    }
  }
  &-button {
    padding: 16px 0;
    border: none;
    outline: none;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.3571))), 18px);
    margin-top: 30px;
    background: white;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 21px;
    color: $whiteText;
    &-btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        cursor: pointer;
      }
    }
    &-social {
      display: flex;
      align-items: center;
      gap: 25px;
      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 18px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &__regis {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;
    label,
    span,
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
    }
    &-check {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    p {
      font-size: 18px;
    }
    &-social {
      //display: flex;
      //align-items: center;
      //gap: 25px;
      display: none;
      &-item {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 18px;
        }
        img {
          width: 24px;
          height: 24px;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
    padding: 10px 15px;
  }
}

button {
  cursor: pointer;
}

.code {
  label {
    font-size: 12px;
    line-height: 14px;
    color: #9A9A9A;
  }
  &-sec {
    margin-top: 20px;
    text-decoration: underline;
  }
  &-che {
    margin-top: 15px;
  }
}

.errorValid {
  color: #f72a2a !important;
  font-size: 14px !important;
}
</style>
