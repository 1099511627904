<template>
  <div class="backgroundFooter" v-if="blocks.banners">
    <div class="footer">
      <div class="footer__top container">
        <router-link to="/" class="footer__top-left">
          <!-- <img style="max-width: 294px;" :src="blocks.banners.item_type_data[0].image" alt="" /> -->
          <img style="max-width: 294px;" src="@/assets/logoWhite.svg" alt="logoWhite" />
        </router-link>
        <div class="footer__top-top">
          <div>
            <div>
              {{$t('contacts')}}
            </div>
            <div class="dop-class">
              <div class="dop-class-item">
                <img :src="require('@/assets/icons/envelope.svg')" alt="envelope" />
                {{ blocks.contacts.item_type_data[0].email }}
              </div>
              <div class="dop-class-item">
                <img :src="require('@/assets/icons/phone.svg')" alt="phone" />
                {{ $t('phone') }}.: {{ blocks.contacts.item_type_data[0].phone }}
              </div>
              <div class="dop-class-item">
                <img :src="require('@/assets/icons/pin.svg')" alt="pin" />
                {{ blocks.contacts.item_type_data[0].address }}
              </div>
              <div @click="$router.push('/contacts')" class="dop-class-more">{{$t('more')}}</div>
            </div>
          </div>
          <div>
            <div>
              {{$t('disclaimer')}}
            </div>
            <div class="dop-class dasdasd">
              {{ blocks.disclaimers.item_type_data[0].content }}
            </div>
          </div>
          <router-link to="/faq">
              {{ blocks.faq.item_type_name }}</router-link>
        </div>
        <div class="footer__top-right">
          <p>{{ $t('stayConnected') }}</p>
          <a :href="item.link" target="blank" v-for="item in blocks.socials.item_type_data" :key="item.id" >
            <img style="width: 25px; height: 25px;"  :src="item.icon" alt="socials" />
          </a>
        </div>
      </div>
      <div class="footer-line"></div>
      <div class="footer__bot container">
        <p>© 2023. {{ $t("footerDown") }}</p>
        <a :href="termsAndConditionsLink" download="">
          <img src="@/assets/icons/document-text.svg" alt="" />
          {{ $t('termsAndConditions') }}
        </a>
      </div>
      <div id="pagetop" @click="toTop">
        <img :src="require('@/assets/icons/backTop.svg')" alt="backTop" />
      </div>
    </div>
  </div>
  <a v-if="blocks.contacts" :href="blocks.contacts.item_type_data[0].whatsapp_link"><img class="whatsappModal" src="@/assets/icons/whatsappModal.svg" /></a>
  <a v-if="blocks.contacts" :href="blocks.contacts.item_type_data[0].email_link"><img class="mailModal" src="@/assets/icons/mailModal.svg" /></a>
    
</template>
<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import store from "@/store";
export default {
  data() {
    return {
      footer: false,
      termsAndConditionsLink: '',
      scTimer: 0,
      scY: 0,
    };
  },
  directives: {
    clickOutSide,
  },
  computed: {
    blocks() {
      let items = {}
      if (this.footer) {
        this.footer.blocks.forEach(element => {
          items[element.item_type] = element
        });
      }
      return items
    }
  },
  created() {
    this.$axios.get('page-by-slug?slug=footer')
    .then(res => {
      this.footer = res.data.data
      this.termsAndConditionsLink = this.blocks.terms.item_type_data[0].file
      store.commit('SET_TERMS_CONDITIONS_DATA', this.termsAndConditionsLink);
    })
    .catch(err => {
      this.$toaster.error('Error')
    })
  },
  methods: {
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
.active {
  img {
    transform: rotate(180deg);
  }
}
.dop-class {
  display: flex;
  color: #BDBDBD; 
  width: 200px;
  font-size: 12px;
  margin-top: 14px;
  flex-direction: column;
  gap: 15px;
  @media screen and (max-width: $laptop + px) {
    width: 150px;
  }
  &-more {
    letter-spacing: 0.04em;
    text-decoration: underline;    
    font-size: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  &-item {
    display: flex;
    gap: 5px;
    align-items: center;
  }
}
#pagetop {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  top: -23px;
  right: 65px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 10, 96, 0.2);
  @media (max-width: $tablet) {
    right: 22px;
    width: 40px;
    height: 40px;
  }
}

.backgroundFooter {
  background: #002158;
  margin-top: 100px;
}
.footer {
  position: relative;
  z-index: 2;
  color: white;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 29px;
    &-top {
      display: flex;
      flex-direction: column;
      width: 33%;
      align-items: flex-start;
      margin: 0 15px;
      gap: 15px;
      font-size: min(max(16px, calc(1rem + ((1vw - 3.5px) * 0.2548))), 20px);
      a {
        color: white;
        text-decoration: none;
      }
      div {
        position: relative;
        width: 100%;
      }
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        width: 100%;
        margin: 0;
        row-gap: 30px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 22px;
    }
  }

  &-line {
    margin-top: 55px;
    margin-bottom: 13px;
    height: 0.1px;
    width: auto !important;
    opacity: 0.9;
    background: #f2f2f2;
  }

  &__bot {
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1961))), 14px);
    padding-bottom: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div, a {
      color: rgba(189, 189, 189, 1);
      text-decoration: none;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1961))), 14px);
      display: flex;
      align-items: center;
      gap: 5px;
      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
  }
}

.dasdasd {
  text-align: justify;
  @media screen and (max-width: $tablet) {
    width: 95vw;
  }
}
.whatsappModal {
  z-index: 5;
  filter: drop-shadow(1px 0px 1px #00c818);
  border-radius: 50%;
  position: fixed;
  bottom: 180px;
  right: 10px;
  cursor: pointer;
  animation: pulse1 6.5s infinite;
  @media (max-width: $tablet) {
    bottom: 100px;
  }
}
.mailModal {
  z-index: 5;
  filter: drop-shadow(1px 0px 1px #0cb2f7);
  position: fixed;
  bottom: 225px;
  right: 10px;
  cursor: pointer;
  animation: pulse2 6.5s infinite;
  @media (max-width: $tablet) {
    bottom: 160px;
  }
}

</style>