<template>
  <div class="main-flex">
    <div id="particles-js"></div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | Mukaman` : `Mukaman` }}</template>
    </metainfo>
    <Header />
    <div class="main">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/layouts/Footer.vue";
import Header from "@/components/layouts/Header.vue";
import Particles from "particles.js";
import {useHead} from "@vueuse/head";
import {computed, reactive} from "vue";

function disableConsole() {
  if (typeof window !== "undefined") {
    window.addEventListener("keydown", function (e) {
      if (
          e.key === "F12" ||
          e.key === "Control" ||
          e.key === "Shift" ||
          (e.key === "C" && e.ctrlKey === true) ||
          (e.key === "I" && e.ctrlKey === true) ||
          (e.key === "J" && e.ctrlKey === true)
      ) {
        e.preventDefault();
      }
    });

    window.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }
}

disableConsole();
export default {
  components: {Header, Footer},
  data: () => ({
    titleMeta: "Mukaman",
    descMeta: "",
  }),
  created() {
    this.initializeSiteData();
    useHead({
      title: 'Mukaman',
      meta: [
        {
          name: computed(() => 'title'),
          content: computed(() => this.siteData.title),
        },
        {
          name: computed(() => 'description'),
          content: computed(() => this.siteData.description),
        },
        {
          name: computed(() => "language"),
          content: computed(() => this.$i18n.locale),
        },
        {
          name: computed(() => "og:title"),
          content: computed(() => this.siteData.title),
        },
        {
          name: computed(() => "og:description"),
          content: computed(() => this.siteData.description),
        },
        {
          property: 'og:url',
          content: computed(() => {
            const languageCode = this.$i18n.locale;
            return `https://mukaman.kz/${languageCode}${this.$route.path}`;
          }),
        },
        {
          property: 'og:locale',
          content: computed(() => this.$i18n.locale),
        },
      ],
      link: [
        {
          rel: 'alternate',
          href: computed(() => {
            return `https://mukaman.kz/`;
          }),
          hreflang: 'x-default',
        },
        {
          rel: 'alternate',
          hreflang: 'ru-RU',
          href: `https://mukaman.kz/ru${this.$route.fullPath}`,
        },
        {
          rel: 'alternate',
          hreflang: 'ru-KZ',
          href: `https://mukaman.kz/ru${this.$route.fullPath}`,
        },
        {
          rel: 'alternate',
          hreflang: 'kk-KZ',
          href: `https://mukaman.kz/kk${this.$route.fullPath}`,
        },
        {
          rel: 'alternate',
          hreflang: 'en-EN',
          href: `https://mukaman.kz/en${this.$route.fullPath}`,
        },
      ],
      htmlAttrs: {
        lang: computed(() => localStorage.getItem("lang") || navigator.language),
      },
    });
  },
  mounted() {
    setTimeout(() => {
      particlesJS("particles-js", {
        "particles": {
          "number": {
            "value": 80,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": "#ffffff"
          },
          "shape": {
            "type": "circle",
            "stroke": {
              "width": 0,
              "color": "#000000"
            },
            "polygon": {
              "nb_sides": 5
            },
            "image": {
              "src": "img/github.svg",
              "width": 100,
              "height": 100
            }
          },
          "opacity": {
            "value": 0.5,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": 3,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#ffffff",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 3,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": "repulse"
            },
            "onclick": {
              "enable": true,
              "mode": "push"
            },
            "resize": true
          },
          "modes": {
            "grab": {
              "distance": 400,
              "line_linked": {
                "opacity": 1
              }
            },
            "bubble": {
              "distance": 400,
              "size": 40,
              "duration": 2,
              "opacity": 8,
              "speed": 3
            },
            "repulse": {
              "distance": 200,
              "duration": 0.4
            },
            "push": {
              "particles_nb": 4
            },
            "remove": {
              "particles_nb": 2
            }
          }
        },
        "retina_detect": true
      });
    }, 100);
  },
  methods: {
    initializeSiteData() {
      this.siteData = reactive({
        title: this.titleMeta,
        description: this.$t('headDescText'),
      });
    },
  },
  watch: {
  },
};
</script>
<style lang="scss">
#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.main-flex {
  display: flex;
  flex-direction: column;
  /* background: #003287; */
  background-image: url('./assets/images/onf.png');
  background-size: 100% auto;
  animation: move-background 30s linear infinite;
  min-height: 100vh;
  align-items: stretch;
  position: relative;
}
@keyframes move-background {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
.kz-text {
  /* p {
    font-family: $openSansFont !important;
  } */
  button {
    font-family: $openSansFont !important;
  }
  input {
    font-family: $openSansFont !important;
  }
  select {
    font-family: $openSansFont !important;
  }
  u {
    font-family: $openSansFont !important;
  }
}
@keyframes pulse1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(1px 0px 3px #00c818);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
    filter: drop-shadow(1px 0px 2px #0cb2f7);
  }
  100% {
    transform: scale(1);
  }
}

.main {
  flex-grow: 1;
}

#app {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

html,
body,
p {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
</style>

