<template>
  <div class="home" v-if="blocks.courses">
    <!-- <div class="home-video">
      <div></div>
      <video autoplay muted loop id="vid">
        <source :src="require('@/assets/images/intro.mp4')" type="video/mp4" />
      </video>
    </div> -->
    <div class="home__main container">
      <div class="home__main-text">
        <p>{{ blocks.banners.item_type_data[0].content }}</p>
        <!-- <button>Оставить заявку</button> -->
      </div>

      <!-- <p>Изучай финансы <br /> <label>профессионально<br /> и эффективно!</label></p> -->
      <img
          class="home__main-banner"
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
          :src="blocks.banners.item_type_data[0].image"
          alt="main"
      />
    </div>
    <div class="home__block1Background">
      <div class="container">
        <h1>{{ $t("learnKey") }}</h1>
        <div class="home__block1" v-if="filteredActiveCourses.length > 2">
          <div
            v-for="(item, idx) in filteredActiveCourses"
            :key="item.id"
            class="home__block1-box"
          >
            <CourseCard :course="item" :elem="course[idx]" />
          </div>
        </div>
        <div class="home__block1-only" v-else>
          <div
              v-for="(item, idx) in filteredActiveCourses"
              :key="item.id"
              class="home__block1-only-box"
          >
            <router-link
                class="course"
                :to="`/courses/${item.slug}`"
            >
<!--              <span></span>-->
<!--              <span></span>-->
<!--              <span></span>-->
              <div class="card">
                <img :src="item.icon" />
                <div class="course__content">
                  <p
                      v-html="item.name"
                      class="titleStyle"
                  >
                  </p>
                  <div class="course__content-desc">
                    <p v-html="item.flip_box_content"></p>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="home__block2 container" v-if="blocks.speakers">
      <div class="home__block2__left">
        <img
          class="home__block2__left-img"
          :src="blocks.speakers.item_type_data[0].image"
        />
        <div class="home__block2__left-back"></div>
      </div>
      <div
        data-aos="zoom-in"
        data-aos-duration="1500"
        class="home__block2__right"
      >
        <h1 class="home__block2__right-p1">
          {{ blocks.speakers.item_type_data[0].name }}
        </h1>
        <div class="home__block2__right-p2">
          <textarea readonly class="aboutus_text" v-html="blocks.speakers.item_type_data[0].content"/>
        </div>
        <!-- <button class="home__block2__right-button">{{ $t('consultation') }}</button> -->
      </div>
    </div>
    <div class="lightning-animation" :class="{ 'animate': animate }"></div>
    <div class="home__block3Background" v-if="blocks.blocks">
      <div class="home__block3 container">
        <h1 class="home__block3-title">
          {{ blocks.blocks.item_type_data[0].name }}
        </h1>
        <div class="home__block3__content">
          <div
            class="home__block3__content__box"
            v-for="(item, idx) in blocks.blocks.item_type_data[0].block_item"
            :key="item.id"
            data-aos="zoom-in-right"
            :data-aos-duration="1000 + idx * 100"
          >
            <img :src="item.icon" />
            <p v-html="item.content"></p>
          </div>
        </div>
      </div>
    </div>

    <div class="home__block4 container">
      <div class="home__block4__content">
        <div class="home__block4__contentWindow">
          <p class="home__block4__content-p1">{{ $t("watchVideo") }}</p>
          <div class="home__block4__content__play" @click="toggleVideo">
            <div class="home__block4__content__play__watch">
              <img src="@/assets/icons/watchTheVideoCircle.svg" />
            </div>
            <div class="home__block4__content__play__button">
              <img src="@/assets/icons/play.svg" />
            </div>
          </div>
        </div>
        <video
            autoplay
            controlslist="nodownload"
            class="video-iframe"
            controls="controls"
            ref="video"
            @contextmenu.prevent
            v-if="isVideoPlaying"
        >
          <source :src="videoSrc" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
        </video>
      </div>
    </div>

    <div class="home__block5Background" v-if="blocks.reviews && !getUser">
      <div
        class="home__block5 container"
        data-aos="zoom-in"
        data-aos-duration="1000"

      >
        <submit-your-app class="home__block5-submit" />
      </div>
    </div>

    <div class="home__block6 container">
      <h1 class="home__block6-p1">{{ $t("reviews") }}</h1>
      <swiper
        class="home__block6-cards"
        :lazy="true"
        :slidesPerView="3"
        :spaceBetween="20"
        :loop="false"
        :breakpoints="{
          1000: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          350: {
            slidesPerView: 1.2,
            spaceBetween: 20,
          },
        }"
        :autoplay="{
          delay: 2000,
          disableOnInteraction: false,
        }"
      >
        <swiper-slide
        class="wipItem"
          v-for="item in blocks.reviews.item_type_data"
          :key="item.id"
        >
          <ReviewCard :review="item" />
        </swiper-slide>
      </swiper>
    </div>
    <div v-if="showCookieModal" class="cookie-modal">
      <div class="cookie-modal__content">
        <p>{{ $t('cookieConsentMessage') }}</p>
        <div class="cookie-buttons">
          <button @click="acceptCookies">{{ $t('acceptCookies') }}</button>
          <button @click="rejectCookies">{{ $t('rejectCookies') }}</button>
<!--          <button @click="customizeCookies">{{ $t('customize') }}</button>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SubmitYourApp from "@/components/SubmitYourApp.vue";
import review from "@/data/review.json";
import course from "@/data/course.json";
import Cookies from 'js-cookie';

import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import SwiperCore, { A11y, Autoplay } from "swiper";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper.min.css";
import ReviewCard from "@/components/ReviewCard.vue";
import CourseCard from "@/components/CourseCard.vue";
import { mapActions, mapGetters } from "vuex";
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);
import {useHead} from "@vueuse/head";
import {computed, reactive} from "vue";

export default {
  components: { SubmitYourApp, ReviewCard, Swiper, SwiperSlide, CourseCard },
  data() {
    return {
      review: review.review,
      course: course.course,
      showCookieModal: false,
      isVideoPlaying: false,
      videoSrc: '',
      showPopUp: false,
      swiper: {
        Navigation1: {
          prevEl: ".leftArrow1",
          nextEl: ".rightArrow1",
        },
        breakpoint1: {
          320: {
            slidesPerView: 1,
          },
          991: {
            slidesPerView: 3,
          },
        },
        pagination1: {
          clickable: true,
          type: "bullets",
          bulletClass: "bulletCustom1",
        },
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters(['getPage', 'getUser']),
    blocks() {
      let items = {};
      if (this.getPage) {
        this.getPage.blocks.forEach((element) => {
          items[element.item_type] = element;
        });
      }
      return items;
    },
    filteredActiveCourses() {
      return this.blocks.courses.item_type_data.filter(
          (item) => item.is_active === "1"
      );
    },
  },
  methods: {
    ...mapActions(["GET_PAGE"]),
    showCookieConsentModal() {
      const hasConsent = Cookies.get('cookieConsent');
      if (!hasConsent) {
        // this.showCookieModal = true;
        this.showCookieModal = !this.getUser;
      }
    },
    acceptCookies() {
      Cookies.set('cookieConsent', 'true', { expires: 365 });
      this.performAuthorization();
      this.showCookieModal = false;
    },
    rejectCookies() {
      Cookies.set('cookieConsent', 'false', { expires: 365 });
      this.performAuthorization();
      this.showCookieModal = false;
    },
    customizeCookies() {
    },
    performAuthorization() {
      this.$store.commit('SET_AUTH', true);
      this.$store.dispatch('requestUser');
    },
    toggleVideo() {
      const videoElement = this.$refs.video;
      if (this.isVideoPlaying) {
        this.stopVideo();
      } else {
        this.playVideo();
      }
    },
    playVideo() {
      this.isVideoPlaying = true;
      this.videoSrc = this.blocks.blocks.item_type_data[0].video;
      if (this.$refs.video) {
        this.$refs.video.play();
      }
    },
    stopVideo() {
      this.isVideoPlaying = false;
      this.videoSrc = '';
      if (this.$refs.video) {
        this.$refs.video.pause();
      }
    },
    initializeSiteData() {
      this.siteData = reactive({
        title: 'Mukaman',
        description: this.$t('headDescText'),
      });
    },
  },
  created() {
    this.GET_PAGE({ main: "main" });
    this.showCookieConsentModal();
    this.initializeSiteData();
    useHead({
      title: 'Mukaman',
      meta: [
        {
          name: computed(() => 'title'),
          content: computed(() => this.siteData.title),
        },
        {
          name: computed(() => 'description'),
          content: computed(() => this.siteData.description),
        },
        {
          name: computed(() => "language"),
          content: computed(() => this.$i18n.locale),
        },
        {
          name: computed(() => "og:title"),
          content: computed(() => this.siteData.title),
        },
        {
          name: computed(() => "og:description"),
          content: computed(() => this.siteData.description),
        },
        {
          property: 'og:url',
          content: computed(() => {
            const languageCode = this.$i18n.locale;
            return `https://mukaman.kz/${languageCode}${this.$route.path}`;
          }),
        },
        {
          property: 'og:locale',
          content: computed(() => this.$i18n.locale),
        },
      ],
      link: [
        {
          rel: 'alternate',
          href: computed(() => {
            return `https://mukaman.kz/`;
          }),
          hreflang: 'x-default',
        },
        {
          rel: 'alternate',
          hreflang: 'ru-RU',
          href: `https://mukaman.kz/ru${this.$route.fullPath}`,
        },
        {
          rel: 'alternate',
          hreflang: 'ru-KZ',
          href: `https://mukaman.kz/ru${this.$route.fullPath}`,
        },
        {
          rel: 'alternate',
          hreflang: 'kk-KZ',
          href: `https://mukaman.kz/kk${this.$route.fullPath}`,
        },
        {
          rel: 'alternate',
          hreflang: 'en-EN',
          href: `https://mukaman.kz/en${this.$route.fullPath}`,
        },
      ],
      htmlAttrs: {
        lang: computed(() => localStorage.getItem("lang") || navigator.language),
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.leftArrow1 {
  cursor: pointer;
  position: absolute;
  right: 35px;
  bottom: 23%;
  z-index: 1;
}
.rightArrow1 {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  bottom: 23%;
  z-index: 1;
}
.home {
  &-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 300px #003287;
    video {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: rotate(180deg);
    }
    div {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 24, 149, 0.5);
    }
  }
  &__main {
    min-height: calc(100vh - 300px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    padding: 40px 0 0 0;
    &-text {
      overflow-y: hidden;
      z-index: 1;
      p {
        width: inherit;
        font-weight: 700;
        font-size: min(
          max(30px, calc(1.875rem + ((1vw - 3.5px) * 2.2293))),
          65px
        );
        line-height: 70px;
        overflow-y: hidden;
        text-transform: uppercase;
        margin-top: 30px;
        max-width: 55%;
        color: $whiteText;
        @media screen and (max-width: 1250px) {
          margin-top: 0px;
          max-width: 62%;
        }
        @media screen and (max-width: $tablet) {
          margin-top: 0px;
          max-width: 70%;
        }
        @media screen and (max-width: $mobile) {
          margin-top: 0px;
          max-width: 100% !important;
        }
      }
      label {
        font-size: min(
          max(22px, calc(1.5rem + ((1vw - 3.5px) * 1.1465))),
          38px
        );
        line-height: 0px !important;
      }
      button {
        margin-top: 20px;
        background: white;
        padding: 16px 30px;
        border: none;
      }
    }
    &-banner {
      position: absolute;
      height: 70%;
      right: 0;
      @media screen and (max-width: $tablet) {
        padding-top: 40px;
      }
      @media screen and (max-width: $mobile) {
        width: 100%;
        position: relative !important;
      }
    }
    @media screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0 80px 0;
      min-height: auto;
      &-text {
        p {
          margin-left: 0;
          line-height: 35px;
          max-width: 80%;
        }
      }
    }
    @media screen and (max-width: $tablet) {
      padding-top: 10px;
    }
  }
  &__block1Background {
    padding-top: 40px;
    padding-bottom: 79px;
    position: relative;
    z-index: 2;
    h1 {
      font-size: min(max(22px, calc(1.375rem + ((1vw - 3.5px) * 1.465))), 45px);
      font-weight: 700;
      margin-bottom: 80px;
      color: $whiteText;
      text-shadow: 55px 55px 66px #000000, 0 0 1em #000000;
    }
  }
  &__block1 {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    height: min(max(364px, calc(22.75rem + ((1vw - 3.2px) * 38.2353))), 754px);
    @media (max-width: $mobile) {
      row-gap: 10px;
      margin-top: 26px;
      height: auto;
    }
    &-box {
      width: calc((100% - 40px) / 3);
      @media (max-width: $mobile) {
        width: 100%;
        height: 155px;
      }
    }
    &-only {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      background: rgba(1, 25, 122, 0.8);
      border: 1px solid white;
      position: relative;
      width: 80%;
      z-index: 2;
      box-shadow: 0 0 100px #003287, 0 0 100px #003287, 0 0 60px #003287;
      @media screen and (max-width: $pc125) {
        height: 50%;
      }
      @media (max-width: $tablet) {
        height: 40%;
      }
      &-box {
        width: 100%;
        height: 590px;
        padding: 55px 50px;
        @media screen and (max-width: $mobile) {
          height: 75%;
          padding: 25px 20px;
        }
      }
    }
  }
  .course {
    background-image: url("@/assets/images/CourseCard2/coursebackonly.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    text-decoration: none;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__content {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .titleStyle {
        color: $whiteText;
        text-align: center;
        text-decoration: none;
        font-weight: 700;
        padding: 20px;
        font-size: min(max(25px, calc(1.5625rem + ((1vw - 3.5px) * 1.2739))), 45px);
        transition: transform 0.3s ease-in-out;
        @media (max-width: $tablet) {
          padding-top: 90px;
          padding-bottom: 0;
        }
      }
      &-desc {
        color: $whiteText;
        text-align: center;
        padding: 50px 150px;
        @media (max-width: $laptop + px) {
          padding: 30px 70px;
        }
        @media (max-width: $mobile) {
          padding: 50px 20px;
        }
        p {
          @media (max-width: $mobile) {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
    }
  }
  .card {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
    img {
      position: absolute;
      left: 5%;
      width: 50px;
      height: 50px;
      margin: 40px 25px;
      @media (max-width: $mobile) {
        width: 30px;
        height: 30px;
      }
    }
  }
  .course span {
    position: absolute;
  }
  .course:hover {
    box-shadow: 0 0 20px #ae00ff, 0 0 35px #001eff, 0 0 60px #ae00ff;
    transition-delay: 0.1s;
  }

  &__block2 {
    padding: 96px 0;
    display: flex;
    justify-content: space-between;
    @media (max-width: $tablet) {
      flex-direction: column;
      gap: 40px;
      padding: 56px 0;
    }
    &__left {
      margin-top: 23px;
      position: relative;
      width: 37.76%;
      @media (max-width: $tablet) {
        width: 100%;
      }
      &-img {
        top: -23px;
        right: -23px;
        width: 100%;
        object-fit: cover;
        height: 571px;
        position: absolute;
        @media (max-width: $tablet) {
          height: 571px;
          width: calc(100% - 20px);
          top: -17px;
          right: 0px;
        }
        @media (max-width: $mobile) {
          height: 370px;
          width: calc(100% - 20px);
          top: -17px;
          right: 0px;
        }
      }
      &-back {
        height: 571px;
        width: 100%;
        background: rgba(255, 255, 255, 0.5);
        @media (max-width: $mobile) {
          height: 370px;
          width: calc(100% - 17px);
          left: 0px;
        }
      }
    }
    &__right {
      width: 57.09%;
      @media (max-width: $tablet) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-p1 {
          text-align: center;
        }
      }
      &-p1 {
        font-style: normal;
        color: $whiteText;
        font-weight: 700;
        font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 0.9804))), 42px);
      }
      &-p2 {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 25px;
        margin-bottom: 41px;
        margin-top: 25px;
        overflow-y: auto;
        .aboutus_text {
          color: $whiteText;
          background: transparent;
          border: none;
          display: inline-table;
          outline: none;
          height: 480px;
          font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.5px) * 0.1911))), 18px);
        }
      }
      &-button {
        color: #000000;
        cursor: pointer;
        padding: 0 38px;
        height: 53px;
        background: white;
        border: none;
        font-size: min(
          max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.3922))),
          18px
        );
        transition: all 0.5s ease;
        &:hover {
          background: linear-gradient(
            45deg,
            rgb(249, 237, 231),
            rgb(255, 227, 213)
          );
        }
      }
    }
  }

  &__block3Background {
    background: rgba(0, 47, 128, 0.7);
  }
  &__block3 {
    padding: 60px 0;
    &-title {
      font-style: normal;
      color: $whiteText;
      font-weight: 700;
      font-size: min(max(30px, calc(2rem + ((1vw - 3.2px) * 0.9804))), 42px);
      @media (max-width: $tablet) {
        text-align: center;
      }
    }
    &__content {
      margin-top: 35px;
      display: flex;
      flex-wrap: wrap;
      // gap: 4.03%;
      justify-content: space-between;
      row-gap: 40px;
      &__box {
        width: 47.25%;
        display: flex;
        align-items: flex-start;
        gap: 22px;
        color: $whiteText;
        font-size: min(
          max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.7843))),
          20px
        );
        img {
          max-width: 70px;
        }
        @media (max-width: $tablet) {
          width: 100%;
        }
      }
    }
  }

  &__block4 {
    padding: 93px 0;
    display: flex;
    justify-content: center;
    position: relative;
    @media (max-width: $tablet) {
      width: 100%;
      padding: 50px 0;
    }
    &__contentWindow {
      height: 62%;
      width: 100%;
      margin: auto;
      background: hsla(0, 0%, 100%, 0.3);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 25px;
      align-items: center;
      @media (max-width: $tablet) {
        width: 100%;
        gap: 12px;
      }
    }
    &__content {
      height: 546px;
      width: 100%;
      // background-size: cover;
      background-image: url("@/assets/images/block4Image.jpg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      position: relative;
      align-items: center;
      @media (max-width: $tablet) {
        height: 540px;
        width: 92%;
        background-size: cover;
      }
      &-p1 {
        font-style: normal;
        color: #003287;
        font-weight: 700;
        font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 0.9804))), 42px);
      }
      &__play {
        cursor: pointer;
        background: hsla(0, 0%, 100%, 0.3);
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.6);
        border-radius: 50%;
        width: 112px;
        height: 112px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.5s ease;
        &:hover {
          transform: scale(1.1);
        }
        &__watch {
          position: absolute;
          // width: 100%;
          border-radius: 50%;
          height: 100%;
          animation: animate-41596bef 10s linear infinite;
        }
        @keyframes animate-41596bef {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        &__button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__block5Background {
    padding: 57px 0 67px 0;
  }
  &__block5 {
    &-submit {
      margin: auto;
      width: 80.97%;
      background: rgba(0, 50, 135, 0.7);
      box-shadow: 0px 4px 14px rgba(134, 134, 134, 0.25);
      @media (max-width: $tablet) {
        width: 95.97%;
      }
    }
  }

  &__block6 {
    display: flex;
    gap: 100px;
    position: relative;
    padding: 72px 0 0px 0;
    &-p1 {
      color: $whiteText;
      font-weight: 700;
      width: 200px;
      font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 0.9804))), 42px);
    }
    &-cards {
      display: flex;
      gap: 20px;
      width: 100%;
      div {
        background: #003183;
      }
    }
    @media screen and (max-width: $mobile) {
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding: 10px 0 0px 0;
      &-p1 {
        text-align: center;
      }
    }
  }
}
.cookie-modal {
  position: fixed;
  color: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: end;
  justify-content: center;
  z-index: 9999;
}

.cookie-modal__content {
  background: #003287;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.cookie-buttons {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.cookie-buttons button {
  cursor: pointer;
  padding: 0 38px;
  height: 53px;
  background: white;
  border: none;
  font-size: min(
      max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.3922))),
      18px
  );
  transition: all 0.5s ease;
  &:hover {
    cursor: pointer;
    background: #011e7d;
    outline: 1px solid white !important;
    color: $whiteText;
  }
}

.video-iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
}
.wipItem {
  height: auto;
}

</style>
<script setup>
</script>