import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import("../views/CoursesView.vue"),
  },
  {
    path: '/courses/:slug',
    name: 'courses-detail',
    component: () => import("../views/CoursesDetailView.vue")
  },
  {
    path: '/news',
    name: 'news',
    component: () => import("../views/NewsView.vue"),
    props: (route) => ({ tab: route.query.tab || null }),
  },
  {
    path: '/news/:slug',
    name: 'news-detail',
    component: () => import("../views/NewsDetailView.vue"),
    props: (route) => ({
      slug: route.params.slug,
      activeTab: route.query.category || null,
    }),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import("../views/FAQView.vue")
  },
  {
    path: '/training',
    name: 'training',
    component: () => import("../views/TrainingView.vue")
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import("../views/ContactsView.vue")
  },
  {
    path: '/basket',
    name: 'basket',
    component: () => import("../views/BasketView.vue")
  },
  {
    path: '/profile/info',
    name: 'profileInfo',
    component: () => import("../views/ProfileView.vue")
  },
  {
    path: '/profile/courses',
    name: 'profileCourses',
    component: () => import("../views/ProfileView.vue")
  },
  {
    path: '/profile/courses/:id',
    name: 'profileCoursesId',
    component: () => import("../views/ProfileView.vue")
  },
  {
    path: '/profile/reviews',
    name: 'profileReviews',
    component: () => import("../views/ProfileView.vue")
  },
  {
    path: '/profile/notification',
    name: 'profileNotification',
    component: () => import("../views/ProfileView.vue")
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import("../views/NotFoundView.vue")
  },
  {
    path: "/paymentauth",
    name: "paymentAuth",
    component: () => import("../components/basketComponent/PaymentAuth.vue"),
    props: true,
  },
  {
    path: "/paymentnotauth",
    name: "paymentNotAuth",
    component: () => import("../components/basketComponent/PaymentNotAuth.vue"),
  },
  {
    path: "/user-agreement",
    name: "user-agreement",
    component: () => import("../views/UserAgreementView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("access_token")) {
      next();
    } else {
      next({ name: "auth" });
    }
  } else {
    next();
  }
});

export default router
