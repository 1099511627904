import { createStore } from 'vuex'
import axios from 'axios';
import { createToaster } from "@meforma/vue-toaster";
import router from "@/router";

const toaster = createToaster({ position: "top-right", duration: 1900 });

export default createStore({
  state: {
    lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.startsWith("ru") ? 'ru' : navigator.language.startsWith("kk") ? 'kaz' : 'en' || 'en',
    page: false,
    auth: localStorage.getItem("access_token") ? true : false,
    user: false,
    basket: {
      courses: JSON.parse(localStorage.getItem("basketCourses")) || [],
      totalPrice: 0,
    },
    lessonComments: {},
    termsAndConditionsLink: '',
  },
  getters: {
    getLang: (state) => state.lang,
    getPage: (state) => state.page,
    getAuth(state) {
      return state.auth;
    },
    getUser: (state) => state.user,
    getSelectedCourse(state) {
      return state.selectedCourse;
    },
    getTotalBasketPrice(state) {
      return state.basket.courses.reduce((total, course) => {
        return total + parseFloat(course.price);
      }, 0);
    },
    isCourseTariffAlreadyInBasket: (state) => (courseID, courseName) => {
      return state.basket.courses.some((course) => course.course_id === courseID || course.name === courseName);
    },
    getLessonComments: (state) => (lessonId) => {
      return state.lessonComments[lessonId] || [];
    },
  },
  mutations: {
    UPDATE_LANG(state, lang) {
      state.lang = lang
      localStorage.setItem("lang", lang);
    },
    SET_PAGE(state, value) {
      state.page = value
    },
    SET_AUTH(state, auth) {
      state.auth = auth;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_SELECTED_COURSE(state, course) {
      state.selectedCourse = course;
    },
    ADD_TO_BASKET(state, course) {
      state.basket.courses.push(course);
      localStorage.setItem("basketCourses", JSON.stringify(state.basket.courses));
    },
    REMOVE_FROM_BASKET(state, index) {
      state.basket.courses.splice(index, 1);
      localStorage.setItem("basketCourses", JSON.stringify(state.basket.courses));
    },
    SET_BASKET_COURSES(state, courses) {
      state.basket.courses = courses;
    },
    CLEAR_BASKET(state) {
      state.basket.courses = [];
      localStorage.removeItem("basketCourses");
    },
    SET_TOTAL_BASKET_PRICE(state, totalPrice) {
      state.basket.totalPrice = totalPrice;
    },
    SET_LESSON_COMMENTS(state, { lessonId, comments }) {
      state.lessonComments = {
        ...state.lessonComments,
        [lessonId]: comments
      };
    },
    SET_TERMS_CONDITIONS_DATA(state, termsAndConditionsLink) {
      state.termsAndConditionsLink = termsAndConditionsLink;
    },
  },
  actions: {
    async GET_PAGE({commit}, slug) {
      commit("SET_PAGE", false)
      await axios.get(`page-by-slug?slug${slug.main ? '=' + slug.main : '=' }${slug.child ? '&child_slug=' + slug.child : '' }`)
          .then(res => {
            commit("SET_PAGE", res.data.data)
          })
          .catch(err => {
            commit("SET_PAGE", false)
          })
    },
    checkAuth({ commit }) {
      if (localStorage.getItem("access_token")) {
        commit("SET_AUTH", true);
        commit("CLEAR_BASKET", true);
      }
      else {
        commit("SET_AUTH", false);
      }
    },
    async requestUser({ commit, dispatch }) {
      let token = localStorage.getItem("access_token");
      if (token) {
        try {
          const response = await axios.get('user-info');
          const user = response.data.data;

          await axios.get('user-notifications')
              .then(res => {
                user.notifications = res.data.data;
                commit("SET_USER", user);
                commit("SET_AUTH", true);
              })
              .catch(err => {
                console.log(err);
              });
        } catch (error) {
          commit("SET_USER", false);
          commit("SET_AUTH", false);
          dispatch("logoutUser");
          localStorage.removeItem("access_token")
          router.push("/");
        }
      } else {
        localStorage.removeItem("access_token");
        commit("SET_USER", false);
        commit("SET_AUTH", false);
      }
    },
    async logoutUser({ commit }) {
      try {
        const response = await axios.post('logout', {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          }
        });
        localStorage.removeItem("access_token");
        toaster.success(response.data.data.message);
        commit("CLEAR_BASKET", true);
        commit("SET_USER", false);
        commit("SET_AUTH", false);
      } catch (err) {
        if (err.response && err.response.message) {
          toaster.error(err.response.message);
        }
      }
    },
    addToBasket({ commit, state, getters }, course) {
      const isCourseTariffAlreadyInBasket = getters.isCourseTariffAlreadyInBasket(course.course_id, course.name);
      if (isCourseTariffAlreadyInBasket) {
        this.$toaster.error(this.$t('toasters.existingTariff'))
      } else {
        commit("ADD_TO_BASKET", course);
        localStorage.setItem("basketCourses", JSON.stringify(state.basket.courses));
      }
    },
    SET_TOTAL_BASKET_PRICE({ commit }, totalPrice) {
      commit("SET_TOTAL_BASKET_PRICE", totalPrice);
    },
    async fetchLessonComments({ commit }, lessonId) {
      try {
        const response = await axios.get(`lesson-comments/${lessonId}`);
        const comments = response.data.data;
        commit("SET_LESSON_COMMENTS", { lessonId, comments });
      } catch (error) {
        console.log(error);
      }
    },
  },
  modules: {
  }
})
