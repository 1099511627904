import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import store from "./store";
import i18n from "./i18n";
import "aos/dist/aos.css";
import axios from "axios";
import VueRecaptcha from "vue3-recaptcha-v2";
import { createToaster } from "@meforma/vue-toaster";
import vue3GoogleLogin from 'vue3-google-login'
import { createMetaManager } from 'vue-meta'
import { createHead } from "@vueuse/head";
const head = createHead();

import { initFacebookSdk, jwtInterceptor, errorInterceptor, fakeBackend } from './_helpers';
import * as Vue from "rxjs";
fakeBackend();
jwtInterceptor();
errorInterceptor();

const axiosInstance = axios.create({
    baseURL: "https://admin.mukaman.kz/api/",
    headers: {
        "Accept-Language": localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.startsWith("ru") ? 'ru' : navigator.language.startsWith("kk") ? 'kaz' : 'en' || 'en'
    },
});
axios.defaults.baseURL = "https://admin.mukaman.kz/api/";
axios.defaults.headers = {
    "Accept-Language": localStorage.getItem('lang') ? localStorage.getItem('lang') : navigator.language.startsWith("ru") ? 'ru' : navigator.language.startsWith("kk") ? 'kaz' : 'en' || 'en',
};

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

const app = createApp(App)
    .use(store)
    .use(i18n)
    .use(vue3GoogleLogin, {
        clientId: '269061030316-aft4gupffuevkc7mt75foqsitdtr2n6f.apps.googleusercontent.com'
    })
    .use(AOS.init())
    .use(VueRecaptcha, {
        siteKey: "6LdAdpkoAAAAAPNvZZSRnfa0mkg3HtQv2UTXq6qr",
        alterDomain: false,
    })
    .use(router)
    .use(head)
    .use(createMetaManager())

app.config.globalProperties.$axios = { ...axiosInstance };
app.config.globalProperties.$toaster = createToaster({ position: "top-right" });
initFacebookSdk().then(app);
app.mount("#app");
