import { createI18n } from "vue-i18n";

let appLanguage = 'en'
if (localStorage.getItem("lang")) {
  appLanguage = localStorage.getItem("lang")
} else {
  appLanguage = navigator.language.startsWith("ru") ? 'ru' : navigator.language.startsWith("kk") ? 'kaz' : 'en'
}
export default createI18n({
  warnHtmlInMessage: 'off',
  locale: appLanguage,
  fallbackLocale: "en",
  messages: {
    kaz: require("./locales/kaz.json"),
    uz: require("./locales/uz.json"),
    ru: require("./locales/ru.json"),
    en: require("./locales/en.json"),
  },
});
