<template>
  <div class="sumbitBackground">
    <div class="sumbit">
      <h1 class="sumbit-p1">{{ $t("submitApp") }}</h1>
      <div class="sumbit-social">
<!--        <div class="sumbit-social-item" @click="loginFB">-->
<!--          <img src="@/assets/icons/facebook.svg" alt="facebook" />-->
<!--          <p>Facebook</p>-->
<!--        </div>-->
        <GoogleLogin :callback="callback"/>
      </div>
      <p class="sumbit-p2">{{ $t("leaveRequest") }}</p>
      <div class="sumbit__inputs">
        <div>
            <input
            type="text"
            v-model="first_name"
            :placeholder="$t('firstName')"
            />
            <template v-for="(error, index) of v$.first_name.$errors" :key="index">
                <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div>
            <input type="text" v-model="last_name" :placeholder="$t('lastName')" />
            <template v-for="(error, index) of v$.last_name.$errors" :key="index">
                <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div>
            <select v-model="course_id">
                <option value="" disabled selected>{{ $t("chooseCourse") }}</option>
                <option v-for="item in courses" :key="item.id" :value="item.id">
                    {{ item.name }}
                </option>
            </select>
            <template v-for="(error, index) of v$.course_id.$errors" :key="index">
                <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div>
            <input type="email" v-model="email" placeholder="E-mail" />
            <template v-for="(error, index) of v$.email.$errors" :key="index">
                <p class="errorValid">{{ error.$message }}</p>
            </template>
        </div>
        <div>
          <input type="text" v-model="v$.phone.$model" v-imask="phoneMask" :placeholder="$t('placeholders.phone')"/>
          <template v-for="(error, index) of v$.phone.$errors" :key="index">
            <p class="errorValid">{{ error.$message }}</p>
          </template>
        </div>
      </div>

      <!-- <img src="@/assets/icons/captcha.jpg"/> -->

      <vue-recaptcha
        class="reCaptcha"
        theme="light"
        size="normal"
        lang="en"
        :tabindex="0"
        @widgetId="recaptchaWidget = $event"
        @verify="callbackVerify($event)"
        @expired="callbackExpired()"
        @fail="callbackFail()"
      />

      <button class="sumbit-button" @click="submitApp">{{ $t("consultation") }}</button>
      <div class="sumbit-checkbox">
        <input v-model="check" type="checkbox" />
        <p v-html="$t('confirmForm')"></p>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";

import {
  required,
  minLength,
  helpers,
  email,
  sameAs,
} from "@vuelidate/validators";

import { ref } from "vue";
import { mapActions } from 'vuex';
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";
import {accountService} from "@/_services";
import { googleTokenLogin, decodeCredential } from "vue3-google-login"
import {IMaskDirective} from "vue-imask";
export default {
  directives: {
    imask: IMaskDirective,
  },
  emits: ['close'],
  data: () => ({
    v$: useVuelidate(),
    courses: false,
    course_id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    check: false,
    phoneMask: {
      mask: "+{7} ({000}) 000-00-00",
      lazy: true,
    },
    operators: [
      700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
      761, 762, 763, 764, 771, 775, 776, 777, 778,
    ],
  }),
  components: { VueRecaptcha },
  setup: () => {
    // Reset Recaptcha
    const { resetRecaptcha } = useRecaptcha();
    const recaptchaWidget = ref(null);

    const callbackVerify = (response) => {
      console.log(response);
    };
    const callbackExpired = () => {
      console.log("expired!");
    };
    const callbackFail = () => {
    };
    // Reset Recaptcha action
    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };

    if (accountService.accountValue) {
      router.push('/');
    }
    const login = () => {
      googleTokenLogin().then((response) => {
        console.log("Handle the response", response)
      })
    }

    return {
      loginGoogle: login,
      recaptchaWidget,
      callbackVerify,
      callbackFail,
      actionReset,
    };
  },
  validations() {
    return {
      first_name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.minLength", { value: 3 }),
          minLength(3)
        ),
      },
      last_name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.minLength", { value: 3 }),
          minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        email: helpers.withMessage(this.$t("errors.email"), email),
      },
      phone: {
        required: helpers.withMessage((this.$t('errors.required')), required),
        minLength: helpers.withMessage((this.$t('errors.phoneFormat')), minLength(18)
        ),
      },
      course_id: {
        required: helpers.withMessage(this.$t("errors.required"), required)
      }
    };
  },
  methods: {
    ...mapActions(["requestUser", "logoutUser"]),
    // async loginFB() {
    //   await accountService.login();
    //   this.requestUser();
    //   setTimeout(() => {
    //     this.$emit('close');
    //     this.$router.push('/profile/info')
    //   }, 200);
    // },
    callback (response) {
      const userData = decodeCredential(response.credential)
      this.$axios.post("auth/google", {
        email: userData.email,
        name: userData.name
      })
          .then(res => {
            localStorage.setItem("access_token", res.data.data.access_token)
            this.$toaster.success(this.$t('toasters.successLogin'));
            this.reserForm()
            this.requestUser()
            setTimeout(() => {
              this.$router.push('/profile/info')
              this.$emit('close')
            }, 200);
          })
          .catch(err => {
            this.$toaster.error(err.response.data.error);
          })
    },
    async getCourses() {
      await this.$axios.get("page-by-slug?slug=courses").then((res) => {
        this.courses = res.data.data.blocks[0].item_type_data;
      });
    },
    async submitApp() {        
        this.v$.$validate();
        if (!this.v$.$invalid && this.check) {
            this.$axios.post('submit-application', {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone: this.phone,
                course_id: this.course_id
            },
            {
                headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            }).then(res => {
                this.$toaster.success(this.$t('toasters.apps'))
                this.course_id = "",
                this.first_name = "",
                this.last_name = "",
                this.email = "",
                this.phone = "",
                this.check = false
                this.v$.$reset()
            }).catch(err => {
                this.$toaster.error(this.$t('toasters.error'))
                this.course_id = "",
                this.first_name = "",
                this.last_name = "",
                this.email = "",
                this.phone = "",
                this.check = false
                this.v$.$reset()
            })
        } else {
            this.$toaster.warning(this.$t('toasters.fullInput'))
        }
  }
    },
  created() {
    this.getCourses();
  }
};
</script>
<style lang="scss" scoped>
.sumbitBackground {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 14px rgba(134, 134, 134, 0.25);
  display: flex;
  justify-content: center;
}
.sumbit {
  width: 63.96%;
  padding: 56px 0 66px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $tablet) {
    width: 83.96%;
  }
  &-p1 {
    padding-bottom: 27px;
    text-align: center;
    font-style: normal;
    color: $whiteText;
    font-weight: 700;
    font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 0.9804))), 42px);
  }
  &-p2 {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 0.3922))), 22px);
    color: $whiteText;
  }
  &-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    width: 100%;
    margin-bottom: 16px;
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 40px;
      width: 100%;
      background: #ffffff;
      padding: 8px 0;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }
  &__inputs {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 15px;
    div {
      box-sizing: border-box;
      width: 49.28%;
      input {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        height: 40px;
        padding: 0 15px;
      }
        select {
        color: #828282;
        box-sizing: border-box;
        width: 100%;
        padding: 0 15px;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        height: 40px;
        }
        p {
            color: red;
            margin-top: 10px;
            font-size: 14px;
        }
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
  &-button {
    color: #000000;
    margin: 30px 0;
    cursor: pointer;
    background: white;
    border: 1px solid #000000;
    width: 100%;
    height: 53px;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.3922))), 18px);
    transition: all 0.5s ease;
    &:hover {
      cursor: pointer;
      background: #011e7d;
      outline: 1px solid white !important;
      color: $whiteText;
    }
  }
  &-checkbox {
    display: flex;
    align-items: flex-start;
    gap: 7px;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3922))), 16px);
    color: #828282;
    input {
      accent-color: white;
      transform: scale(1.2);
    }
  }
}
</style>