<template>
    <div class="review">
        <p class="review-title">
        <img :src="require('@/assets/icons/shapka.svg')" alt="shapka" />
        <span v-if="review.user">{{ review.user.name }}</span></p>
        <p>{{ review.comment }}</p>
    </div>
</template>
<script>
export default {
    props: ['review']
}
</script>
<style lang="scss" scoped>
.review {
    width: 100% !important;
    height: 100%;
    padding: 24px 12px;
    background: #003183;
    border: 1px solid white;
    box-shadow: 0px 4px 14px rgba(0, 10, 96, 0.15);
    color: white;
    &-title {
        display: flex;
        align-items: center;
        font-size: 20px;
        gap: 5px;
        margin-bottom: 10px;
    }
}
</style>